import React, { Component } from 'react';
import { withAdminLayout } from '../../layout';
import { withAppContext } from '../../components/withAppContext';
import { adminApiUserlist, adminApiUserUpdate } from '../../util/adminApi';
import { getUser } from '../../util/auth';
import { AdminUserTable } from '../../components/AdminUserTable/Index';
import { FormattedMessage } from 'react-intl';
import { Input } from '../../components/Form/Input';
import { registerTypes } from '../../components/Register';
import { Select } from '../../components/Form/Select';
import { Checkbox } from '../../components/Form/Checkbox';
// import { Pagination } from './pagination';

const quotaStateType = [
  'Alle',
  'Grün',
  'Gelb',
  'Rot'
];

const registerAll = [
  'Alle'
].concat(registerTypes);

const activeType = [
  'Alle',
  'aktiv',
  'inaktiv'
];

class AdminUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quotaState: quotaStateType[0],
      type: registerAll[0],
      active: activeType[0],
      userlist: [],
      currentFilter: {
        fieldname: 'contingent',
        direction: 'DESC',
      },
      currentPage: 0,
      itemsPerPage: 1000,
      totalUsers: 0,
      isLoading: false,
      search: '',
    };
  }

  /**
   * @param {import('react').ChangeEvent<HTMLInputElement>} event
   */
  handleChange = event => {
    // update interal state as we type
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  /**
   * @param {import('react').ChangeEvent<HTMLInputElement>} event
   */
  handleChangeMultiple = event => {
    // update interal state as we type
    let value = Array.from(event.target.selectedOptions, option => option.value)
    console.log(value)
    this.setState({
      [event.target.name]: value,
    });
  };

  /**
   * @param {import('react').FormEvent} event
   */
  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    const users = await adminApiUserlist(
      getUser().token,
      this.state.itemsPerPage,
      this.state.currentPage * this.state.itemsPerPage,
      [this.state.currentFilter],
      this.state.search,
      this.state.type,
      this.state.quotaState,
      this.state.active,
    );
    this.setState({ userlist: users.result, isLoading: false });
  };

  /**
   * @param {import('react').ChangeEvent<HTMLInputElement>} event
   */
  handleUpdate = event => {
    switch (event.target.name) {
      default:
        this.setState({
          [event.target.name]: event.target.value,
        });
    }
  };

  fetchUserList = () => {
    return adminApiUserlist(
      getUser().token,
      this.state.itemsPerPage,
      this.state.currentPage * this.state.itemsPerPage,
      [this.state.currentFilter],
    );
  };


  fetchUserListSearch = () => {
    return adminApiUserlist(
      getUser().token,
      this.state.itemsPerPage,
      this.state.currentPage * this.state.itemsPerPage,
      [this.state.currentFilter],
      this.state.search,
      this.state.type,
      this.state.quotaState,
      this.state.active,
    );
  };

  async componentDidMount() {
    const response = await this.fetchUserList();
    this.setState({ totalUsers: response.total, userlist: response.result });
  }

  updateUser = async (id, updatedUser) => {
    console.log('AdminUser::updateUser id', id);
    console.log('AdminUser::updateUser updatedUser', updatedUser);

    const result = await adminApiUserUpdate(getUser().token, updatedUser);
    this.setState({
      userlist: this.state.userlist.map(user => {
        return user.id === id ? result : user;
      }),
    });
  };

  /**
   * pageNumber starts at 0
   */
  navigateToPage = async pageNumber => {
    const response = await this.fetchUserList();
    const pages = Math.ceil(response.total / this.state.itemsPerPage);

    if (pageNumber > pages - 1) {
      pageNumber = pages - 1;
    }
    if (pageNumber < 0) {
      pageNumber = 0;
    }

    // console.log(pageNumber);

    this.setState({
      totalUsers: response.total,
      userlist: response.result,
      currentPage: pageNumber,
    });
  };

  calculateIndicesToShow = (currentIndex, totalPages) => {
    // special case where it is not possible to use the regular logic
    if (totalPages < 5) {
      // just show all pages
      return [0, 1, 2, 3];
    } else {
      return [];
    }
  };

  render() {
    const { isLoading, type, quotaState, active } = this.state;

    return (
      <>
        <h1 className="admin">
          <FormattedMessage id="dashboard.Admin User" />
        </h1>
        <form
          method="post"
          onSubmit={event => {
            this.handleSubmit(event);
          }}
        ><Input type="text" 
        className="form-input"
        type="search"
        name="search"
        placeholder=" "
        handleChange={this.handleUpdate}></Input>

        <div className="form-group">
          <label className="form-label"><FormattedMessage id="profile.i_am_we_are" /></label>
          <Select
            name="type"
            handleChange={this.handleChangeMultiple}
            options={registerAll}
            value={type}
          />
          <label className="form-label"><FormattedMessage id="dashboard.Contingent" /></label>
          <Select
            name="quotaState"
            handleChange={this.handleChange}
            options={quotaStateType}
            value={quotaState}
          />
        </div>
        <button className={`btn ${isLoading && 'loading'}`} disabled={isLoading} type="submit">
          Suchen {/*<FormattedMessage id="loginregister.register" />*/}
        </button>
        </form>
        
        {this.state.userlist && (
          <AdminUserTable
            users={this.state.userlist}
            updateUser={this.updateUser}
            currentFilter={this.state.currentFilter}
            onFilterChange={newFilter => {
              this.setState({ currentFilter: newFilter }, async () => {
                const response = await this.fetchUserListSearch();
                this.setState({ userlist: response.result, totalUsers: response.total });
              });
            }}
          />
        )}
        {/* <Pagination currentPage={this.state.currentPage} totalItems={this.state.totalUsers} /> */}
      </>
    );
  }
}

const customProps = {
  withAuth: true,
};

export default withAdminLayout(customProps)(withAppContext(AdminUser));
